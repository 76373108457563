import React from 'react';
import logo from '../images/logo.png';

const Logo = (props :any) => {
  return (
    <div className='logo'>
      <img src={logo} alt="kanami-logo"/>
    </div>
  );
};

export default Logo;