import React from 'react';
import { Routes, Route } from "react-router-dom"; // 追加
import './App.css';
// components
import Header from './components/Header';
import Top from './components/Top';
import Profile from './components/Profile';
import Gidelines from './components/Gidelines';

function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<Top />}/>
        <Route path="/profile" element={<Profile />}/>
        <Route path="/gidelines" element={<Gidelines />}/>
      </Routes>
    </div>
  );
}

export default App;
