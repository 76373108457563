import React from 'react';
//img
import background from '../images/background.png';
import profile from '../images/profile.png';
// style
import '../style/profile.css';

const Profile = (props :any) => {
  return (
    <div id="profile">
      <div className='content-wrap'>
        <div className='title'>-Profile-</div>
        <div className='profile-img'>
          <div className='profile'>
            <img src={profile} alt="profile"/>
          </div>
          <div className='background'>
            <img src={background} alt="backgruond"/>
          </div>
        </div>
        <div className='link-wrap'>
          <div>
            <a href="https://x.com/kanami_k73" className="flowbtn my_x1">
              <i className="fa-brands fa-x-twitter"></i>
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/@kanami-channel" className="flowbtn my_youtube1">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
        <div className='profile-list'>
          <div className='list-item'>
            <div>FAN MARK</div>
            <div>🌸🤍</div>
          </div>
          <div className='list-item'>
            <div>誕生日</div>
            <div>7月3日</div>
          </div>
          <div className='list-item'>
            <div>身長</div>
            <div>158cm</div>
          </div>
          <div className='list-item'>
            <div>すきなこと</div>
            <div>歌、ホラーゲーム、お絵描き</div>
          </div>
        </div>
        <div className='title'>-Tags-</div>
        <div className='profile-list'>
          <div className='list-item'>
            <div>総合</div>
            <div>#花波のはなうた</div>
          </div>
          <div className='list-item'>
            <div>ファンアート</div>
            <div>#花波のはなたば</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;