import React from 'react';
//img
import background from '../images/background.png';
// style
import '../style/gidelines.css';

const Gidelines = (props :any) => {
  return (
    <div id="gidelines">
      <div className='content-wrap'>
        <div className='title'>-Gidelines-</div>
        <div className='gideline-text'>
         {/* <div className='sub'>切り抜き動画について</div>
          <p className='text'>
            大歓迎です！

            ○注意事項
            ・切り抜き元配信URL、チャンネルURLを概要欄に記載してください
            ・悪意のある切り抜き方をしないでください
            ・配信を編集を加えずにそのままアップロードすることはおやめください。
          </p> */}
          <div className='sub'>ファンアートについて</div>
          <p>
            大歓迎です！<br/>
            『#花波のはなたば』のついたものは、SNSやサムネイル等に使わせていただく場合があります。<br/>
            センシティブなもの、グロテスクなもの等はハッシュタグをつけないでください。<br/><br/>

            🌸有償依頼ファンアート<br/>
            大歓迎です！<br/>
            もし活動に使って良いのでしたら、依頼する際、絵師様に以下をお願いしていただき、データを送っていただけると嬉しいです。<br/>
            ⓵サムネイル等への使用許諾<br/>
            ②イラストの透過データ(あればサムネに非常に使いやすいです!)
          </p>
          <div className='sub'>AIについて</div>
          <p>
            AIイラストの公開は禁止です。<br/>
            花波の姿・他の方が描かれたファンアート等をAIに学習させてインターネット上にアップロードする行為はおやめください。<br/>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Gidelines;