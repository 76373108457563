import React from 'react';
// img
import charactor from '../images/charactor.png';
import background from '../images/background.png';
// style
import '../style/top.css';

const Top = (props :any) => {
  return (
    <div id='top'>
      <div className='background'>
        <img src={background} alt="backgruond"/>
      </div>
      <div className='content-wrap'>
        <div className='charactor'>
          <img src={charactor} alt="kanami"/>
        </div>
      </div>
    </div>
  );
};

export default Top;