import React, {useState} from 'react';
import { Link } from "react-router-dom";
// mui
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from "@mui/material/Drawer";
import IconButton from '@mui/material/IconButton';
// components
import Logo from '../organisms/Logo'
// style
import '../style/header.css';

const Header = (props :any) => {
  const [open, setOpen] = useState(false);
 
  const handleDrawerOpen = () => {
      setOpen(true);
  }

  const handleDrawerClose = () => {
      setOpen(false);
  }

  return (
    <header id="app-header">
      <div className='header-wrap'>
        <Link to='/'>
          <Logo/>
        </Link>
        <nav className='nav'>
          <Link to='/'><div>TOP</div></Link>
          <Link to='/profile'><div>PROFILE</div></Link>
          <Link to='/gidelines'><div>GIDELINES</div></Link>
          <div>
            <a href="https://x.com/kanami_k73" className="flowbtn my_x1">
              <i className="fa-brands fa-x-twitter"></i>
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/@kanami-channel" className="flowbtn my_youtube1">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </nav>
        <div className='menu-btn'>
          <MenuIcon onClick={handleDrawerOpen} style={{fontSize: 30}}/>
        </div>
        <Drawer anchor="right" open={open} onClose={handleDrawerClose} PaperProps={{ style: { width: '70%' } }} className='drawer'>
          <div className='close-btn'>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon style={{fontSize: 30}}/>
            </IconButton>
          </div>
          <div className='list-wrap'>
            <Link to='/' onClick={handleDrawerClose}><div>TOP</div></Link>
            <Link to='/profile' onClick={handleDrawerClose}><div>PROFILE</div></Link>
            <Link to='/gidelines' onClick={handleDrawerClose}><div>GIDELINES</div></Link>
            <div>
              <a href="https://x.com/kanami_k73" className="flowbtn my_x1">
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </div>
            <div>
              <a href="https://www.youtube.com/@kanami-channel" className="flowbtn my_youtube1">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </Drawer>
      </div>
    </header>
  );
};

export default Header;